import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GridList = makeShortcode("GridList");
const GridListItem = makeShortcode("GridListItem");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <GridList columns="2" mdxType="GridList">
  <GridListItem title="Sainsbury's voice guidelines" titleElement="h3" linkText="Read the Sainsbury's voice guidelines" linkTo="/content/writing-for-our-brands/sainsburys-voice-guidelines" border mdxType="GridListItem">
    A set of UX-specific voice guidelines to make sure your words always sound
    like Sainsbury’s.
  </GridListItem>
  <GridListItem title="Argos voice guidelines" titleElement="h3" linkText="Read the Argos voice guidelines" linkTo="/content/writing-for-our-brands/argos-voice-guidelines" border mdxType="GridListItem">
    A set of UX-specific voice guidelines to make sure your words always sound
    like Argos.
  </GridListItem>
  <GridListItem title="Habitat voice guidelines" titleElement="h3" linkText="Read the Habitat voice guidelines" linkTo="/content/writing-for-our-brands/habitat-voice-guidelines" border mdxType="GridListItem">
    A set of UX-specific voice guidelines to make sure your words always sound
    like Habitat.
  </GridListItem>
  <GridListItem title="Nectar voice guidelines" titleElement="h3" linkText="Read the Nectar voice guidelines" linkTo="/content/writing-for-our-brands/nectar-voice-guidelines" border mdxType="GridListItem">
    A set of UX-specific voice guidelines to make sure your words always sound
    like Nectar.
  </GridListItem>
  <GridListItem title="Comparing brand voices" titleElement="h3" linkText="Go to comparing brand voices" linkTo="/content/writing-for-our-brands/comparing-brand-voices" border mdxType="GridListItem">
    A guide to understanding how our different brands sound in certain digital
    interactions.
  </GridListItem>
    </GridList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      